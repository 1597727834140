import Login from 'components/Login';
import config from 'config';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'reducer';
import { errorAlert, successAlert } from 'reducer/slices/alertSlice';
import { bottomDrawer, hideDrawer } from 'reducer/slices/drawerSlice';
import { hideLoading, showLoading } from 'reducer/slices/loadingSlice';
import gather from 'tools/gather';

const ForgetPassword = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isForm, setIsForm] = useState(true);
  const [code, setCode] = useState('');

  const confirmCode = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.apiUrl}/v1/auth/forgetPassword/confirm`).post({
      email: email,
      code: code,
      newPassword: password,
    });

    if (result.code === 200) {
      dispatch(successAlert('Your password changed successful'));
      dispatch(hideDrawer());
      setTimeout(() => dispatch(bottomDrawer(<Login />)), 300);
    } else {
      dispatch(errorAlert(t('code_' + result?.message || config.errorCode['errorFound'])));
    }

    dispatch(hideLoading());
  };

  const reset = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.apiUrl}/v1/auth/forgetPassword`).post({
      email: email,
    });

    if (result.code === 200) {
      setIsForm(false);
    } else {
      dispatch(errorAlert(t('code_' + result?.message || config.errorCode['errorFound'])));
    }

    dispatch(hideLoading());
  };

  return (
    <div className='fullPop'>
      <div className='container containerAuto'>
        <div className='topSignup'>
          <div className='topButton'>
            <i></i>
            <h3>{t('resetPassword')}</h3>
          </div>
          <div className='logo logoSmall'></div>
        </div>
        <div className='splashInfo splashInfoCenter'>
          {isForm ? (
            <>
              <div className='form'>
                <div className='inputParent'>
                  <span>{t('email')}</span>
                  <input type='text' autoComplete='off' placeholder='Place your email' onChange={(e) => setEmail(e.target.value)} />
                </div>
                <span className='blueButton blueButtonFull' onClick={() => reset()}>
                  <i>{t('sendCode')}</i>
                </span>
              </div>
            </>
          ) : (
            <>
              <div className='form'>
                <div className='inputParent'>
                  <span>{t('confirmCode')}</span>
                  <input type='text' name='code' placeholder='Code' value={code} onChange={(e) => setCode(e.target.value)} />
                </div>
                <div className='inputParent'>
                  <span>{t('newPassword')}</span>
                  <input type='password' autoComplete='off' placeholder='New Password' onChange={(e) => setPassword(e.target.value)} />
                </div>
                <span className='blueButton blueButtonFull' onClick={() => confirmCode()}>
                  <i>{t('checkCode')}</i>
                </span>
              </div>
            </>
          )}
        </div>
        <span className='goLogin' onClick={() => dispatch(hideDrawer())}>
          {t('dontHaveAccount')}
        </span>
      </div>
    </div>
  );
};

export default ForgetPassword;
