import config from 'config';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'reducer';
import { errorAlert, successAlert } from 'reducer/slices/alertSlice';
import { hideDrawer } from 'reducer/slices/drawerSlice';
import { hideLoading, showLoading } from 'reducer/slices/loadingSlice';
import gather from 'tools/gather';
import utils from 'tools/utils';

const Send = ({ data }: any) => {
  const dispatch = useAppDispatch();
  const [receiver, setReceiver] = useState('');
  const [amount, setAmount] = useState('');
  const [done, setDone] = useState<any>();
  const { t } = useTranslation();
  const useUser = useAppSelector((state) => state.user);

  const balance = useUser.wallet.find((e: any) => e.coin === data?.symbol)?.free || 0;

  const fee = data?.fee.send.type === 'percent' ? Number((Number(amount) * data?.fee.send.amount) / 100) || 0 : data?.fee.send.amount;
  let receive = Number(Number(amount) + fee) || 0;
  if (receive < 0) {
    receive = 0;
  }

  const sendCrypto = async () => {
    const confirmAction = window.confirm(`You want to send ${amount} ${data?.symbol} to ${receiver}. Are you sure?`);
    if (confirmAction) {
      confirmCrypto();
    }
  };

  const confirmCrypto = async () => {
    console.log('amount', amount);
    console.log('amount', data);
    if (amount < data?.limit?.send?.min || amount > data?.limit?.send?.max) {
      dispatch(errorAlert(t('amountRange')));
      return;
    }

    dispatch(showLoading());

    const result = await gather(`${config.apiUrl}/v1/wallet/send`, true).post({
      coin: data?.symbol,
      amount: amount,
      receiver: receiver,
    });

    if (result.code === 200) {
      setDone(result.data);
      // dispatch(hideDrawer());
      dispatch(successAlert(t('successSend')));
    } else {
      dispatch(errorAlert(t('code_' + result?.message || config.errorCode['errorFound'])));
    }

    dispatch(hideLoading());
  };

  return (
    <div className='fullPop'>
      <div className='container'>
        <div className='backParent' onClick={() => dispatch(hideDrawer())}>
          <div className='backIcon'></div>
        </div>
        <div className='pageTop'>
          <div className='pageTopTitle'>{t('send')}</div>
          <div className='receiveIcon receiveIconLarge sendIcon'></div>
        </div>
        <div className='pageIcon'>
          <img crossOrigin='anonymous' src={`${config.apiUrl}/${data?.icon}`} className='popupImage iconImage' />
          <div className='pageIconText'>{data?.symbol}</div>
        </div>
        {done ? (
          <>
            <div className='spacerFlex'>
              <div className='sent'>
                <h5>{t('sentSuccessful')}</h5>
                <span>
                  {t('status')}: {done.status}
                </span>
                <span>
                  {t('hash')}:
                  <br />
                  {done.hash}
                </span>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className='spacerFlex'>
              <div className='form'>
                <div className='inputParent'>
                  <div className='inputParentMax'>
                    <span>{t('amount')}</span>
                    <span onClick={() => setAmount((balance - fee).toString())}>
                      {t('maxWithFee')}: {utils.formatter(balance)}
                    </span>
                  </div>
                  <input type='text' placeholder='Amount' value={amount} onChange={(e) => setAmount(e.target.value)} />
                </div>
                <div className='inputParent'>
                  <span className='selectAddress'>
                    {t('receiver')}
                    {/* <i className='color' onClick={() => dispatch(bottomDrawer2(<Address coin={data?.symbol} setReceiver={setReceiver} />))}>
                  Select From List
                </i> */}
                  </span>
                  <input type='text' placeholder='Receiver Address' value={receiver} onChange={(e) => setReceiver(e.target.value)} />
                </div>
              </div>
              <div className='limitData'>
                <span>
                  {t('minAmount')}: {data?.limit?.send?.min || 0}
                </span>
                <span>
                  {t('maxAmount')}: {data?.limit?.send?.max || 0}
                </span>
              </div>
              <div className='feeData'>
                <span>
                  {t('final')}: {utils.formatter(receive)}
                </span>
                <span>
                  {t('fee')}: {fee}
                </span>
              </div>
              {/* {data?.symbol === 'USDT' && (
            <div className='limitData limitDataColor'>
              <span>Fee at other wallets : 13-27 TRX</span>
              <span>Fee at uWallet : 0 (Later will be just 3-6 TRX)</span>
            </div>
          )} */}
            </div>
            <div className='blueButton blueButtonFull blueButtonBottom' onClick={() => sendCrypto()}>
              <i>{t('send')}</i>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Send;
