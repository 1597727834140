import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'reducer';
import { hideDrawer } from 'reducer/slices/drawerSlice';

const Contact = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  return (
    <div className='fullPop'>
      <div className='container'>
        <div className='backParent' onClick={() => dispatch(hideDrawer())}>
          <div className='backIcon'></div>
        </div>
        <div className='pageTop'>
          <div className='pageTopTitle'>{t('contactUs')}</div>
          {/* <div className='receiveIcon receiveIconLarge'></div> */}
        </div>
        <div className='spacerFlex'>
          <div className='form list'>
            <div className='showToken'>
              <div>
                <span>Utopia:</span>
                <span>UNKNOWN13</span>
              </div>
              <div className='ucodeParent'>
                <span className='ucode'></span>
              </div>
            </div>
            <div className='showToken'>
              <div>
                <span>Telegram:</span>
                <span>
                  <a href='https://t.me/HotVoucher_Supp' target='_blank'>
                    @HotVoucher_Supp
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
