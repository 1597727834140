import config from 'config';
import { useQRCode } from 'next-qrcode';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'reducer';
import { errorAlert, successAlert } from 'reducer/slices/alertSlice';
import { hideDrawer } from 'reducer/slices/drawerSlice';
import { hideLoading, showLoading } from 'reducer/slices/loadingSlice';
import gather from 'tools/gather';

const activeTabTitle = {
  send: 'Send',
  create: 'Withdraw',
};

const Express = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [address, setAddress] = useState('');
  const [result, setResult] = useState('');
  const [done, setDone] = useState(false);
  const [receiver, setReceiver] = useState('');
  const [email, setEmail] = useState('');
  const useData = useAppSelector((state) => state.data);
  const { Image } = useQRCode();
  const [isActive, setIsActive] = useState(activeTabTitle.send);

  const data = useData.assets.find((e: any) => e.symbol === 'USDT');
  const to = useData.assets.find((e: any) => e.symbol === 'UUSD');

  const rate = data.swap.find((e: any) => e.coin === to.symbol)?.percent || 0;

  const isActiveTab = () => {
    const rows: any = [];

    Object.entries(activeTabTitle).forEach(([k, v]) => {
      rows.push(
        <span className={isActive === v ? 'inActiveTabs' : ''} onClick={() => setIsActive(v)} key={k}>
          {v}
        </span>,
      );
    });

    return rows;
  };

  const copy = (value: string) => {
    navigator.clipboard.writeText(value);
    dispatch(successAlert(t('copiedSucc')));
  };

  const checkExpress = async (address: string) => {
    await new Promise((res) => setTimeout(res, 5000));

    const result = await gather(`${config.apiUrl}/v1/express/check`, true).post({
      address: address,
      email: email,
    });

    if (result.code === 200) {
      setDone(true);
      setResult(result.data.result);
    } else {
      checkExpress(address);
    }
  };

  const start = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.apiUrl}/v1/express`, true).post({
      coin: 'USDT',
      to: 'UUSD',
      receiver: isActive === activeTabTitle.send ? receiver : 'voucher',
      email: email,
    });

    if (result.code === 200) {
      setAddress(result.data.address);
      checkExpress(result.data.address);
    } else {
      dispatch(errorAlert(t('code_' + result?.message || config.errorCode['errorFound'])));
    }

    dispatch(hideLoading());
  };

  return (
    <div className='fullPop'>
      <div className='container'>
        <div className='backParent' onClick={() => dispatch(hideDrawer())}>
          <div className='backIcon'></div>
        </div>
        <div className='pageTop'>
          <div className='pageTopTitle'>Fast Change</div>
          <div className='receiveIcon receiveIconLarge swapIcon'></div>
        </div>
        {done ? (
          <>
            <p className='success'>Your convert successful</p>
            <p className='success' onClick={() => copy(result)}>
              {result}
            </p>
          </>
        ) : address === '' ? (
          <>
            <div className='spacerFlex'>
              <div className='tabs tabsExpress'>{isActiveTab()}</div>
              <div className='form'>
                <div className='inputParent'>
                  <span>Email</span>
                  <input type='text' placeholder='Your email' onChange={(e) => setEmail(e.target.value)} />
                </div>
                {isActive === activeTabTitle.send && (
                  <div className='inputParent'>
                    <span>Receiver Address</span>
                    <input type='text' placeholder='Receiver UUSD address' onChange={(e) => setReceiver(e.target.value)} />
                  </div>
                )}
              </div>
              <div className='feeData'>
                <span>
                  {'Rate'}: {rate}%
                </span>
              </div>
            </div>
            <div className='blueButton blueButtonFull blueButtonBottom' onClick={() => start()}>
              <i>Start</i>
            </div>
          </>
        ) : (
          <>
            <div className='spacerFlex'>
              <br />
              <p>Send USDT to this address and we will convert it to UUSD and send it to your receiver address</p>
              <div className='publicCopy'>
                <span>{address}</span>
                <Image text={address!} options={{ type: 'image/jpeg', quality: 1, margin: 2, width: 80 }} />
              </div>
            </div>
            <div className='blueButton blueButtonFull blueButtonBottom' onClick={() => copy(address)}>
              <i>Copy Address</i>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Express;
