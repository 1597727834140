import config from 'config';
import Cookies from 'js-cookie';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'reducer';
import { errorAlert, successAlert } from 'reducer/slices/alertSlice';
import { hideDrawer } from 'reducer/slices/drawerSlice';
import { hideLoading, showLoading } from 'reducer/slices/loadingSlice';
import gather from 'tools/gather';

const Register = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isForm, setIsForm] = useState(true);
  const [code, setCode] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [repassword, setRepassword] = useState('');

  const confirmCode = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.apiUrl}/v1/auth/signup/confirm`).post({
      email: email,
      code: code,
    });

    if (result.code === 200) {
      Cookies.set('refreshToken', result.data.refreshToken, { expires: 365 });
      Cookies.set('accessToken', result.data.accessToken, { expires: 365 });

      dispatch(successAlert(t('registerSuccessful')));

      window.location.reload();
    } else {
      dispatch(errorAlert(t('code_' + result?.message || config.errorCode['errorFound'])));
      dispatch(hideLoading());
    }
  };

  const signup = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.apiUrl}/v1/auth/signup`).post({
      email: email,
      password: password,
      repassword: repassword,
    });

    if (result.code === 200) {
      setIsForm(false);
    } else {
      dispatch(errorAlert(t('code_' + result?.message || config.errorCode['errorFound'])));
    }

    dispatch(hideLoading());
  };

  return (
    <div className='fullPop'>
      <div className='container containerAuto'>
        <div className='topSignup'>
          <div className='topButton'>
            <i></i>
            <h3>{t('register')}</h3>
          </div>
          <div className='logo logoSmall'></div>
        </div>
        <div className='splashInfo splashInfoCenter'>
          {isForm ? (
            <>
              <div className='form'>
                <div className='inputParent'>
                  <span>{t('email')}</span>
                  <input type='text' placeholder='Email' onChange={(e) => setEmail(e.target.value)} />
                </div>
                <div className='inputParent'>
                  <span>{t('password')}</span>
                  <input type='password' autoComplete='off' placeholder='Password' onChange={(e) => setPassword(e.target.value)} />
                </div>
                <div className='inputParent'>
                  <span>{t('repassword')}</span>
                  <input type='password' autoComplete='off' placeholder='Repassword' onChange={(e) => setRepassword(e.target.value)} />
                </div>
                <span className='blueButton blueButtonFull' onClick={() => signup()}>
                  <i>{t('registerNow')}</i>
                </span>
              </div>
            </>
          ) : (
            <>
              <div className='form'>
                <div className='inputParent'>
                  <span>{t('confirmCode')}</span>
                  <input type='text' name='code' placeholder='Code' value={code} onChange={(e) => setCode(e.target.value)} />
                </div>
                <span className='blueButton blueButtonFull' onClick={() => confirmCode()}>
                  <i>{t('checkCode')}</i>
                </span>
              </div>
            </>
          )}
        </div>
        <span className='goLogin' onClick={() => dispatch(hideDrawer())}>
          {t('haveAccount')}
        </span>
      </div>
    </div>
  );
};

export default Register;
