import config from 'config';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'reducer';
import { errorAlert, successAlert } from 'reducer/slices/alertSlice';
import { hideDrawer } from 'reducer/slices/drawerSlice';
import { hideLoading, showLoading } from 'reducer/slices/loadingSlice';
import gather from 'tools/gather';
import utils from 'tools/utils';

const currencyTitle = {
  usd: 'USD',
  irr: 'IRR',
};

const Receive = () => {
  const dispatch = useAppDispatch();
  const [receiver, setReceiver] = useState('');
  const [code, setCode] = useState('');
  const [currency, setCurrency] = useState('');
  const { t, i18n } = useTranslation();
  const [coin, setCoin] = useState('');

  const useData = useAppSelector((state) => state.data);

  const coinPrice = useData.market.find((e: any) => e.symbol === coin)?.price || 0;
  const coinToman = useData.market.find((e: any) => e.symbol === coin)?.toman || 0;

  const isActiveTab = () => {
    const rows: any = [];

    Object.entries(currencyTitle).forEach(([k, v]) => {
      rows.push(
        <span className={currency === v ? 'inActiveTabs' : ''} onClick={() => setCurrency(v)} key={k}>
          {v}
        </span>,
      );
    });

    return rows;
  };

  const sendCrypto = async () => {
    const confirmAction = window.confirm(`${t('wantToRec')} ${code}$ ${t('from')} ${receiver}. ${t('areYouSure')}?`);
    if (confirmAction) {
      confirmCrypto();
    }
  };

  const coinTabOption = () => {
    const rows: any = [];

    useData.assets.forEach((v: any, k: number) => {
      if (v.symbol === 'TRX' || v.symbol === 'USDT' || v.symbol === 'UUSD') {
        rows.push(<option key={k}>{v.symbol}</option>);
      }
    });

    return rows;
  };

  const confirmCrypto = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.apiUrl}/v1/cash/receive`, true).post({
      code: code,
      receiver: receiver,
      coin: coin,
    });

    if (result.code === 200) {
      dispatch(hideDrawer());
      dispatch(successAlert(t('successSend')));
    } else {
      dispatch(errorAlert(t('code_' + result?.message || config.errorCode['errorFound'])));
    }

    dispatch(hideLoading());
  };

  return (
    <div className='fullPop'>
      <div className='container'>
        <div className='backParent' onClick={() => dispatch(hideDrawer())}>
          <div className='backIcon'></div>
        </div>
        <div className='pageTop'>
          <div className='pageTopTitle'>{t('receive1XBet')}</div>
          <div className='receiveIcon receiveIconLarge'></div>
        </div>
        <div className='spacerFlex'>
          {/* <div className='tabs'>
            {isActiveTab()}
          </div> */}
          {/* {!currency ? (
            <div className='direct'><br/><br/>Please selet your 1xbet account currency</div>
          ) : ( */}
          <>
            <div className='form formBottom'>
              <div className='inputParent'>
                <span>{t('coin')}</span>
                <div className='selectBox'>
                  <select defaultValue={coin} onChange={(e) => setCoin(e.target.value)}>
                    <option value={''}>{t('selectCoin')}</option>
                    {coinTabOption()}
                  </select>
                </div>
              </div>
              <div className='inputParent'>
                <span>{t('code')}</span>
                <input type='text' placeholder='Code' onChange={(e) => setCode(e.target.value)} />
              </div>
              <div className='inputParent'>
                <span>{t('receiverID')}</span>
                <input type='text' placeholder='Receiver ID' onChange={(e) => setReceiver(e.target.value)} />
              </div>
            </div>
          </>
          <div className='feeData'>
            <span>USD: {utils.formatter(utils.toFixed(coinPrice * 1.005, 3))}</span>
            <span>IRR: {utils.formatter(utils.toFixed(coinToman * 1.005, 0))}</span>
          </div>
        </div>
        <div className='blueButton blueButtonFull blueButtonBottom' onClick={() => sendCrypto()}>
          <i>{t('receive')}</i>
        </div>
      </div>
    </div>
  );
};

export default Receive;
