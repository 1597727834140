import Contact from 'components/Contact';
import CreateToken from 'components/CreateToken';
import TokenList from 'components/TokenList';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'reducer';
import { bottomDrawer, hideDrawer } from 'reducer/slices/drawerSlice';
import version from '../../../package.json';

const Menu = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const logout = async () => {
    Cookies.set('refreshToken', '');
    Cookies.set('accessToken', '');
    window.location.reload();
  };

  const openWebsite = () => {
    window.open('https://uwallet.biz', '_blank');
  };

  const opener = (data: any) => {
    dispatch(hideDrawer());

    setTimeout(() => {
      dispatch(bottomDrawer(data));
    }, 500);
  };

  return (
    <div className='fullPop'>
      <div className='container'>
        <div className='menuLogo'>
          <div className='backParent' onClick={() => dispatch(hideDrawer())}>
            <div className='backIcon'></div>
          </div>
          <div className='logo logoMenu'></div>
        </div>
        <div className='lineMenu'></div>
        <div className='menuList'>
          <span onClick={() => openWebsite()}>{t('visitWebsite')}</span>
          <span onClick={() => opener(<Contact />)}>{t('contactUs')}</span>
          <span onClick={() => logout()}>{t('logout')}</span>
          <i>{t('developerTools')}</i>
          <span onClick={() => opener(<TokenList />)}>{t('tokensList')}</span>
          <span onClick={() => opener(<CreateToken />)}>{t('createToken')}</span>
          <span onClick={() => window.open('https://uwallet.biz/api.pdf', '_blank')}>{t('documents')}</span>
        </div>
        <span className='version'>
          {t('version')}: {version.version}
        </span>
      </div>
    </div>
  );
};

export default Menu;
