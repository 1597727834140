import CreateVoucher from 'components/CreateVoucher';
import Receive from 'components/Receive';
import Send from 'components/Send';
import UseVoucher from 'components/UseVoucher';
import config from 'config';
import { random } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { useAppDispatch } from 'reducer';
import { successAlert } from 'reducer/slices/alertSlice';
import { bottomDrawer, hideDrawer } from 'reducer/slices/drawerSlice';
import { hideLoading, showLoading } from 'reducer/slices/loadingSlice';
import gather from 'tools/gather';

const Coin = () => {
  const dispatch = useAppDispatch();
  const [listTr, setListTr] = useState([]);
  const [list, setList] = useState([]);
  const [search, setSearch] = useState('');
  const { t, i18n } = useTranslation();

  const activeTabTitle = {
    transactions: 'Transactions',
    vouchers: 'Vouchers',
  };

  const subTabTitle = {
    used: t('used'),
    unused: t('unused'),
  };

  const location = useLocation();
  const { data } = location.state;
  const [isActive, setIsActive] = useState(activeTabTitle.transactions);
  const [subActive, setSubActive] = useState(subTabTitle.used);
  const [group, setGroup] = useState('');

  let filter = list.filter((e: any) => (subActive === subTabTitle.unused ? e.isActive === true : e.isActive === false)) as any;

  if (group) {
    filter = filter
      .filter((e: any) => e.tag === group)
      .map((e: any) => {
        return {
          ...e,
          tag: random(100000, 999999),
        };
      });
  }

  filter = filter.reduce((r: any, a: any) => {
    const tag = a.tag || random(100000, 999999);
    r[tag] = r[tag] || [];
    r[tag].push(a);
    return r;
  }, Object.create(null));

  filter = Object.values(filter).sort((a: any, b: any) => b[0].updatedAt - a[0].updatedAt);

  const isActiveTab = () => {
    const rows: any = [];

    Object.entries(activeTabTitle).forEach(([k, v]) => {
      rows.push(
        <span className={isActive === v ? 'inActiveTabs' : ''} onClick={() => setIsActive(v)} key={k}>
          {v}
        </span>,
      );
    });

    return rows;
  };

  const isSubTab = () => {
    const rows: any = [];

    Object.entries(subTabTitle).forEach(([k, v]) => {
      rows.push(
        <span className={subActive === v ? 'inActiveTabs' : ''} onClick={() => setSubActive(v)} key={k}>
          {v}
        </span>,
      );
    });

    return rows;
  };

  const copyAll = () => {
    let vouchers = '';
    filter.forEach((e: any) => {
      e = e[0];
      if (!e.error) {
        vouchers += e.code + ' | ' + e.amount + '\n';
      }
    });

    if (vouchers) {
      navigator.clipboard.writeText(vouchers);
      dispatch(successAlert(t('copiedSucc')));
    }
  };

  const copyAddress = (address: string) => {
    navigator.clipboard.writeText(address);
    dispatch(successAlert(t('copiedSucc')));
  };

  const showListSub = () => {
    const rows: any = [];
    filter.forEach((e: any, i: number) => {
      const count = e.length;
      e = e[0];

      rows.push(
        count === 1 ? (
          <div className='showToken showTrans showVoucher' key={i}>
            <img crossOrigin='anonymous' src={`${config.apiUrl}/icons/${e.coin.toLowerCase()}.png`} />
            <div>
              <span>
                {e.coin === 'PM' || e.coin === 'PME' || e.coin === 'cVoucher' ? (
                  <>
                    {t('code')}: {e.code.split('-')[0]}
                    <br />
                    {t('number')}: {e.code.split('-')[1]}
                  </>
                ) : (
                  <>
                    {t('code')}: {e.code}
                  </>
                )}
              </span>
              <span>
                {t('amount')}: {e.amount} {e.coin} <i className={`activeVoucher ${!e.isActive && 'usedVoucher'}`}>{e.isActive ? t('active') : t('used')}</i>
              </span>
              {e?.data?.receiver && (
                <span>
                  {t('receiver')}: {e.data.receiver}
                </span>
              )}
              <span>
                {t('date')}: {moment(e.updatedAt).format('MMM D, YYYY, HH:mm:ss')}
              </span>
            </div>
            <div>
              <span className='copy' onClick={() => copyAddress(e.code)}></span>
            </div>
          </div>
        ) : (
          <div className='showToken showTrans showVoucher' onClick={() => setGroup(e.tag)} key={i}>
            <img crossOrigin='anonymous' src={`${config.apiUrl}/icons/${e.coin.toLowerCase()}.png`} />
            <div>
              <span>
                {count} {t('vouchers')}
              </span>
              <span>
                {t('date')}: {moment(e.createdAt).format('MMM D, YYYY, HH:mm:ss')}
              </span>
            </div>
          </div>
        ),
      );
    });

    if (rows.length === 0) {
      rows.push(<div key={1000000}>{t('noItem')}</div>);
    }

    return rows;
  };

  const openForVoucher = (a: string) => {
    dispatch(hideDrawer());

    setTimeout(() => {
      if (a !== 'create') {
        dispatch(bottomDrawer(<UseVoucher data={data} />));
      } else {
        dispatch(bottomDrawer(<CreateVoucher data={data} />));
      }
    }, 300);
  };

  const copy = (address: string) => {
    navigator.clipboard.writeText(address);
    dispatch(successAlert(t('copiedSucc')));
  };

  const showList = () => {
    const rows: any = [];

    listTr.forEach((e: any, i) => {
      rows.push(
        <div className='showToken showTrans showVoucher' key={i}>
          <img crossOrigin='anonymous' src={`${config.apiUrl}/icons/${e.coin.toLowerCase()}.png`} />
          <div>
            <span>
              {t(e.side)} {e.amount} {e.coin} {t('by')} {t(e.type)}
            </span>
            <span>
              {t('id')}: {t(e.id)}
            </span>
            <span>
              {t('status')}: {t(e.status)}
            </span>
            {(e?.data?.hash || e?.data?.reference) && (
              <span>
                {t('hash')}: {e?.data?.hash || e?.data?.reference}
              </span>
            )}
            <span>
              {t('date')}: {moment(e.createdAt).format('MMM D, YYYY, HH:mm:ss')}
            </span>
          </div>
          <div>
            <span
              className='copy'
              onClick={() => {
                (e?.data?.hash || e?.data?.reference) && copy(e?.data?.hash || e?.data?.reference);
              }}
            ></span>
          </div>
        </div>,
      );
    });

    if (rows.length === 0) {
      rows.push(<div key={1000000}>{t('listEmpty')}</div>);
    }

    return rows;
  };

  const init = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.apiUrl}/v1/transaction?coin=${data.symbol}&id=${search}`, true).get();
    const resultSub = await gather(`${config.apiUrl}/v1/voucher`, true).get();
    if (result.code === 200 && resultSub.code === 200) {
      setListTr(result.data);
      setList(resultSub.data);
    } else {
      dispatch(hideDrawer());
    }

    dispatch(hideLoading());
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <Link className='backParent' to={'/'}>
        <div className='backIcon'></div>
      </Link>
      <div className='pageTop'>
        <div className='pageTopTitle'>
          {data.symbol}
          <i>{data.name}</i>
        </div>
        <div className='settingIcon'></div>
      </div>
      <div className='fastMenu'>
        {data.trade.send && (
          <div className='actionItem' onClick={() => dispatch(bottomDrawer(<Send data={data} />))}>
            <i></i>
            <div>{t('send')}</div>
          </div>
        )}
        {data.trade.receive && (
          <div className='actionItem' onClick={() => dispatch(bottomDrawer(<Receive data={data} />))}>
            <i className='receiveIcon'></i>
            <div>{t('receive')}</div>
          </div>
        )}
        {/* <div className='actionItem' onClick={() => {}}>
          <i className='swapIcon'></i>
          <div>Swap</div>
        </div> */}
        {data.voucher.use && (
          <div className='actionItem' onClick={() => dispatch(bottomDrawer(<UseVoucher data={data} />))}>
            <i className='voucherIcon'></i>
            <div>{t('deposit')}</div>
          </div>
        )}
        {data.voucher.create && (
          <div className='actionItem' onClick={() => dispatch(bottomDrawer(<CreateVoucher data={data} />))}>
            <i className='voucherIcon'></i>
            <div>{t('withdraw')}</div>
          </div>
        )}
        {/* <Link className='actionItem' to='/fast' state={{ coin: data.symbol, type: 'send' }}>
          <i className='historyIcon'></i>
          <div>{t('send')} 1X</div>
        </Link> */}
      </div>

      <div className='tabs'>{isActiveTab()}</div>
      {/* <div className='splashContainer'> */}
      {isActive === activeTabTitle.transactions ? (
        <>
          <div className='searchBox'>
            <input placeholder='Search ID' onChange={(e) => setSearch(e.target.value)} />
            <span onClick={() => (search && search.length !== 24 ? alert(t('idNotValid')) : init())}></span>
          </div>
          <div className='form list listUp'>{showList()}</div>
        </>
      ) : (
        <>
          <div className='tabs'>{isSubTab()}</div>
          {group && (
            <div style={{ display: 'flex', gap: 40, justifyContent: 'center' }}>
              <div className='backToList' onClick={() => setGroup('')}>
                « {t('backToList')}
              </div>
              <div className='backToList' onClick={() => copyAll()}>
                {t('copyAll')}
              </div>
            </div>
          )}
          <div className='form list listUp'>{showListSub()}</div>
        </>
      )}
      {/* </div> */}
      {/* <Link className='blueButton blueButtonFull blueButtonBottom' to='/fast' state={{ coin: data.symbol, type: 'send' }}><i>Send To 1XBet</i></Link> */}
    </>
  );
};

export default Coin;
