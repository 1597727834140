import config from 'config';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'reducer';
import { errorAlert, successAlert } from 'reducer/slices/alertSlice';
import { hideDrawer } from 'reducer/slices/drawerSlice';
import { hideLoading, showLoading } from 'reducer/slices/loadingSlice';
import { getProfile } from 'reducer/slices/userSlice';
import gather from 'tools/gather';

// const titleTab = {
//   telegram: 'By Telegram',
//   email: 'By Email',
// };

const TwoStep = () => {
  const { t, i18n } = useTranslation();
  const titleTab = {
    telegram: t('byTelegram'),
    email: t('byEmail'),
  };
  const dispatch = useAppDispatch();
  const [id, setId] = useState('');
  const [tab, setTab] = useState(titleTab.telegram);
  const useUser = useAppSelector((state) => state.user);

  const listTab = () => {
    const rows: any = [];

    Object.entries(titleTab).forEach(([k, v]) => {
      rows.push(
        <span className={tab === v ? 'inActiveTabs' : ''} onClick={() => setTab(v)} key={k}>
          {v}
        </span>,
      );
    });

    return rows;
  };

  const activate = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.apiUrl}/v1/user/twoStep`, true).post({
      type: tab === titleTab.telegram ? 'telegram' : 'email',
      receiver: tab === titleTab.telegram ? id : useUser.profile.email,
    });

    if (result.code === 200) {
      await dispatch(getProfile());
      dispatch(successAlert(t('twoStepActSucc')));
      dispatch(hideDrawer());
    } else {
      dispatch(errorAlert(t('code_' + result?.message || config.errorCode['errorFound'])));
    }

    dispatch(hideLoading());
  };

  return (
    <div className='fullPop'>
      <div className='container'>
        <div className='backParent' onClick={() => dispatch(hideDrawer())}>
          <div className='backIcon'></div>
        </div>
        <div className='pageTop'>
          <div className='pageTopTitle'>{t('twoStep')}</div>
          {/* <div className='receiveIcon receiveIconLarge'></div> */}
        </div>
        <div className='spacerFlex'>
          <div className='tabs'>{listTab()}</div>
          <div className='form'>
            {tab === titleTab.telegram ? (
              <>
                <div className='joinTo'>
                  {t('atFirst')}: <b onClick={() => 'https://t.me/uWalletBizBOT'}>t.me/uWalletBizBOT</b>
                </div>
                <input type='text' placeholder='Register code' onChange={(e) => setId(e.target.value)} />
              </>
            ) : (
              <>
                <div className='joinTo'>{t('codeEmail')}:</div>
                <input type='text' placeholder='Email' disabled value={useUser.profile.email} />
              </>
            )}
          </div>
          {/* <span className='blueButton' onClick={() => activate()}>Activate</span> */}
        </div>
        <div className='blueButton blueButtonFull blueButtonBottom' onClick={() => activate()}>
          <i>{t('activate')}</i>
        </div>
      </div>
    </div>
  );
};

export default TwoStep;
