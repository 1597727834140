import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';

const Language = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    Cookies.set('language', i18n.language, { expires: 30 * 24 * 60 * 60, path: '/' });
    document.documentElement.lang = i18n.language;
  };

  return (
    <>
      <div onClick={() => changeLanguage('fa')}>FA</div>
      <div onClick={() => changeLanguage('en')}>EN</div>
    </>
  );
};

export default Language;
