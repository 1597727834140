import Onex from 'components/Onex';
import Bottom from 'layouts/Bottom';
import Header from 'layouts/Header';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'reducer';
import { bottomDrawer } from 'reducer/slices/drawerSlice';

const Fast = () => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();

  return (
    <>
      <Header title={'Fast'} />
      <div className='splashContainer'>
        <br />
        <p className='infoAir'>{t('selectOption')}:</p>
        <div className='fast1Data'>
          <div onClick={() => dispatch(bottomDrawer(<Onex />))}>{t('sendReceive1x')}»</div>
          {/* <div onClick={() => dispatch(bottomDrawer(<Auto type='pmVoucher' />))}>Create auto PM voucher »</div> */}
        </div>
      </div>
      <Bottom />
    </>
  );
};

export default Fast;
