import TokenList from 'components/TokenList';
import config from 'config';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'reducer';
import { errorAlert, successAlert } from 'reducer/slices/alertSlice';
import { bottomDrawer, hideDrawer } from 'reducer/slices/drawerSlice';
import { hideLoading, showLoading } from 'reducer/slices/loadingSlice';
import gather from 'tools/gather';

const CreateToken = ({ data }: any) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [name, setName] = useState(data?.name || '');
  const [callback, setCallback] = useState(data?.callback || '');

  const create = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.apiUrl}/v1/token${data ? '/' + data.id : ''}`, true)[data ? 'put' : 'post']({
      name: name,
      callback: callback,
    });

    if (result.code === 200) {
      dispatch(hideDrawer());

      setTimeout(() => {
        dispatch(bottomDrawer(<TokenList />));
      }, 300);

      dispatch(successAlert('Token created successful'));
    } else {
      dispatch(errorAlert(t('code_' + result?.message || config.errorCode['errorFound'])));
    }

    dispatch(hideLoading());
  };

  return (
    <div className='fullPop'>
      <div className='container'>
        <div className='backParent' onClick={() => dispatch(hideDrawer())}>
          <div className='backIcon'></div>
        </div>
        <div className='pageTop'>
          <div className='pageTopTitle'>{t('token')}</div>
          {/* <div className='receiveIcon receiveIconLarge sendIcon'></div> */}
        </div>
        <div className='spacerFlex'>
          <div className='form'>
            <div className='inputParent'>
              <span>{t('tokenName')}</span>
              <input type='text' placeholder='Name' value={name} onChange={(e) => setName(e.target.value)} />
            </div>
            <div className='inputParent'>
              <span>
                {t('callbackUrl')} ({t('optional')})
              </span>
              <input type='text' placeholder='URL' value={callback} onChange={(e) => setCallback(e.target.value)} />
            </div>
          </div>
        </div>
        <span className='blueButton blueButtonFull blueButtonBottom' onClick={() => create()}>
          <i>{t('send')}</i>
        </span>
      </div>
    </div>
  );
};

export default CreateToken;
