import config from 'config';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'reducer';
import { successAlert } from 'reducer/slices/alertSlice';
import { hideDrawer } from 'reducer/slices/drawerSlice';
import { hideLoading, showLoading } from 'reducer/slices/loadingSlice';
import gather from 'tools/gather';

let need = 0;

const Invite = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [invites, setInvites] = useState([]);

  const listFriends = () => {
    const rows: any = [];

    invites?.forEach((e: any, i: number) => {
      rows.push(
        <i key={i}>
          {i + 1}. {e}
        </i>,
      );
    });

    if (rows.length === 0) {
      return <div className='infoAir empty'>{t('noFriends')}</div>;
    }

    return rows;
  };

  const init = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.apiUrl}/v1/user/friends`, true).get();
    if (result.code === 200) {
      setInvites(result.data);
    } else {
      dispatch(hideDrawer());
    }

    dispatch(hideLoading());
  };

  const copy = (voucher: string) => {
    navigator.clipboard.writeText(voucher);
    dispatch(successAlert(t('copiedSucc')));
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <div className='fullPop'>
      <div className='container'>
        <div className='backParent' onClick={() => dispatch(hideDrawer())}>
          <div className='backIcon'></div>
        </div>
        <div className='pageTop'>
          <div className='pageTopTitle'>{t('friends')}</div>
        </div>
        <div className='spacerFlex airFlex'>
          {!telegram ? (
            <p className='infoAir' style={{ color: 'red' }}>
              {t('mustMiniApp')}
            </p>
          ) : (
            <div className='spacerFlex airFlex'>
              <p className='infoAir'>
                {t('shareLink')}
                <br />
                {t('getMoreBonus')}!
                <div onClick={() => copy(`https://t.me/uwalletbiz_bot/uwallet?startapp=${webApp?.user?.id}`)}>
                  https://t.me/uwalletbiz_bot/uwallet?startapp={webApp?.user?.id}
                </div>
              </p>
              {/* <div className='yourFriends'>{listFriends()}</div> */}
            </div>
          )}
          {/* <p className='infoAir'>
            Share your invite link with your friends
            <br />
            and get more bonus!
            <div onClick={() => copy(`https://t.me/uwalletbiz_bot/uwallet?startapp=${webApp?.user?.id}`)}>
              https://t.me/uwalletbiz_bot/uwallet?startapp={webApp?.user?.id}
            </div>
          </p> */}
          <div className='yourFriends'>{listFriends()}</div>
        </div>
      </div>
    </div>
  );
};

export default Invite;
