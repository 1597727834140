import config from 'config';
import Bottom from 'layouts/Bottom';
import Header from 'layouts/Header';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'reducer';
import { errorAlert, successAlert } from 'reducer/slices/alertSlice';
import { hideDrawer } from 'reducer/slices/drawerSlice';
import { hideLoading, showLoading } from 'reducer/slices/loadingSlice';
import gather from 'tools/gather';
import utils from 'tools/utils';

// const currencyTitle = {
//   buy: 'Buy Energy',
//   history: 'History',
// }

const Energy = () => {
  const allData = useAppSelector((state) => state.data);
  const { t, i18n } = useTranslation();

  const currencyTitle = {
    buy: t('buyEnergy'),
    history: t('history'),
  };

  const dispatch = useAppDispatch();
  const [receiver, setReceiver] = useState('');
  const [amount, setAmount] = useState('');
  const [time, setTime] = useState(allData.energy.time[0].duration);
  const [activate, setActivate] = useState(false);
  const [currency, setCurrency] = useState(currencyTitle.buy);
  const [list, setList] = useState([]);

  const userData = useAppSelector((state) => state.user);
  const trxData = userData.wallet.find((d: any) => d.coin === 'TRX');
  const findPrice = allData.energy.time.find((d: any) => d.duration == time).price;

  const trxPrice = parseInt(((findPrice * Number(amount) * (Number(time) + (Number(time) < 86400 ? 86400 : 0))) / 86400).toFixed(0)) / Math.pow(10, 6);
  const final = (activate ? allData.energy.activate : 0) + trxPrice;

  const isActiveTab = () => {
    const rows: any = [];

    Object.entries(currencyTitle).forEach(([k, v]) => {
      rows.push(
        <span className={currency === v ? 'inActiveTabs' : ''} onClick={() => setCurrency(v)} key={k}>
          {v}
        </span>,
      );
    });

    return rows;
  };

  const buy = async () => {
    const confirmAction = window.confirm(`${t('youWantBuy')} ${amount} ${t('energyFor')} ${receiver}. ${t('areYouSure')}?`);
    if (confirmAction) {
      confirmBuy();
    }
  };

  const confirmBuy = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.apiUrl}/v1/energy`, true).post({
      amount: amount,
      activate: activate,
      receiver: receiver,
      time: time,
    });

    if (result.code === 200) {
      dispatch(successAlert(t(t('successSend'))));
      setActivate(false);
      setAmount('');
      setReceiver('');
    } else {
      dispatch(errorAlert(t('code_' + result?.message || config.errorCode['errorFound'])));
    }

    dispatch(hideLoading());
  };

  const tabOption = () => {
    const rows: any = [];

    allData.energy.time.forEach((v: any, k: number) => {
      rows.push(
        <option value={v.duration} key={k}>
          {v.name}
        </option>,
      );
    });

    return rows;
  };

  const showList = () => {
    const rows: any = [];

    list.forEach((e: any, i) => {
      rows.push(
        <div className='showToken showTrans showVoucher' key={i}>
          <div>
            <span>
              {t('amount')}: {utils.formatter(e.amount)} {t('energy')} = {e.price} TRX
            </span>
            <span>
              {t('receiver')}: {e.receiver}
            </span>
            <span>
              {t('duration')}: {e.time} seconds
            </span>
            <span>
              {t('activate')}: {e.activate} TRX
            </span>
            <span>
              {t('date')}: {moment(e.createdAt).format('MMM D, YYYY, HH:mm:ss')}
            </span>
          </div>
        </div>,
      );
    });

    if (rows.length === 0) {
      rows.push(<div key={1000000}>{t('listEmpty')}</div>);
    }

    return rows;
  };

  const init = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.apiUrl}/v1/energy`, true).get();
    if (result.code === 200) {
      console.log(result);
      setList(result.data);
    } else {
      dispatch(hideDrawer());
    }

    dispatch(hideLoading());
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <Header title={'Tron Energy'} />

      <div className='tabs'>{isActiveTab()}</div>
      <div className='splashContainer'>
        {currency === currencyTitle.buy ? (
          <>
            <div className='direct directNone'>
              <i>
                {t('yourBalance')}: <b>{trxData?.free || 0} TRX</b>
              </i>
            </div>
            <div className='form formBottom'>
              <div className='inputParent'>
                <span>{t('duration')}</span>
                <div className='selectBox'>
                  <select defaultValue={time} onChange={(e) => setTime(e.target.value)}>
                    {tabOption()}
                  </select>
                </div>
              </div>
              <div className='inputParent'>
                <span>{t('amount')}</span>
                <input type='text' placeholder={`Amount of energy`} value={amount} onChange={(e) => setAmount(e.target.value)} />
              </div>
              <div className='inputParent'>
                <span>{t('receiver')}</span>
                <input type='text' placeholder='Receiver Address' value={receiver} onChange={(e) => setReceiver(e.target.value)} />
              </div>
              <div className='settingLiner settingLinerFull' onClick={() => setActivate(!activate)}>
                <span className={`checkbox ${activate && 'checkboxActive'}`}></span>
                <i>
                  {t('iNeed')} ({allData.energy.activate} TRX)
                </i>
              </div>
              <div className='feeData'>
                <span>
                  {t('payable')}: {utils.formatter(utils.toFixed(final, 3))} TRX
                </span>
              </div>
              <div className='blueButton blueButtonFull blueButtonBottom' onClick={() => buy()}>
                <i>{t('buyEnergy')}</i>
              </div>
            </div>
          </>
        ) : (
          <div className='form list listUp listUpEnergy'>{showList()}</div>
        )}
      </div>
      <Bottom />
    </>
  );
};

export default Energy;
